import React, {useState,useEffect} from 'react'
// css
import '../../css/regParticipant-modal.css'
// scss
import '../../css/btnfinish.scss'
// cookies
import Cookies from 'js-cookie';
// axios
import axios from 'axios';
// icons
import { FaFacebook,FaInstagram,FaYoutube,FaInfoCircle,FaCameraRetro,FaTiktok  } from "react-icons/fa";
import { PiStarFourBold } from "react-icons/pi";
import { RiFileUserLine,RiStarSmileFill } from "react-icons/ri";
import { TbPhotoEdit } from "react-icons/tb";
import { FiEdit } from "react-icons/fi";
import { IoCaretBack } from "react-icons/io5";
import { FaCloud } from "react-icons/fa6";
import { PiMagicWandFill } from "react-icons/pi";
// assets
import samplePic from '../../assets/imgs/sampleImg.jpg'
import ParticipantAggreement from '../handling/participantAggreement';
import noUser from '../../assets/imgs/userError.png'

import defaultCover from '../../assets/imgs/defaultCover.png'
import defaultProfile from '../../assets/imgs/defaultProfile.png'

// datafetch
import FetchUser from '../datafetch/fetchUser';
import FlowerLoader from '../loader/flower';
import Confetti from 'react-confetti';


const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const RegisterParticipant = ({setopenLogModal,openParticipanForm,setOpenParticipantForm}) => {

    const [userLog, setCurrentUser] = useState(null)
    const [userInfo,setUserInfo] = useState(null)
    const [initialData, setInitialData] = useState(null)

    const [flowerLoader, setFlowerLoader] = useState(false)

    const [coverPhoto,setCoverphoto] = useState(defaultCover)
    const [coverFile,setCoverfile] = useState(null)
    const [profilePicture,setProfilePicture] = useState(defaultProfile)
    const [profileFile,setProfileFile] = useState(null)

    const [mainCard,setMainCard] = useState(defaultProfile)
    const [displayMainCard,setDisplayMainCard] = useState(null)
    const [mainCardfile,setMainCardfile] = useState(null)
    const [faceCard1,setFacecard1] = useState(defaultProfile)
    const [faceCard1file,setFacecard1file] = useState(null)
    const [faceCard2,setFacecard2] = useState(defaultProfile)
    const [faceCard2file,setFacecard2file] = useState(null)

    const [userAgree,setUserAgree] = useState(false)
    const [formPage, setFormPage] = useState('first')

    const [error,setError] = useState(false)
    const [handleError, setHandleError] = useState('')


    const [genderSelected, setGenderSelected] = useState(false);
    const [genderSelect,setGenderSelect] = useState('')

    const [finishedForm,setFinishedForm] = useState(false)
    const [onConfetti,setOnConfetti] = useState(true)

    let timeoutId;
    
    const proceedPage = () => {
        switch (true) {
            case formPage === 'first':
                if (!userAgree) {
                    setHandleError('Please Check the Agreement First')
                    setError(true)
                    clearErrorTimeout();
                    timeoutId = setTimeout(() => {
                        setError(false);
                    }, 2000);
                    return
                }
                setError(false)
                setHandleError('')
                setFlowerLoader(true)
                clearErrorTimeout();
                timeoutId = setTimeout(() => {
                    setFlowerLoader(false);
                }, 3000);
                setFormPage('second')
                break;
            
            case formPage === 'second':
                setFormPage('third')
                break;

            case formPage === 'third':
                setFormPage('fourth')
                break;

            default:
                break;
        }
    }

    const previousPage = () => {
        switch (true) {
            case formPage === 'second':
                setFormPage('first')
                break;

            case formPage === 'third':
                setFormPage('second')
                break;

            case formPage === 'fourth':
                setFormPage('third')
                break;
        
            default:
                break;
        }
    }

    const clearErrorTimeout = () => {
        clearTimeout(timeoutId); // Clear the timeout
    };

    const openlogin = () => {
        setopenLogModal('login')
    }

    
    const handleChange = (index, field, value) => {
        const updatedData = [...userInfo];
        updatedData[index][field] = value;
        setUserInfo(updatedData);
    };

    const handleChangeUsername = (index, field, value) => {
        const updatedData = [...userInfo];
        updatedData[index][field] = value;
        setUserInfo(updatedData);
    };

    // change display images
    const changeCover = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a URL for the selected file
            setCoverphoto(URL.createObjectURL(file));
            setCoverfile(file)
        }
    };

    const changeProfile = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a URL for the selected file
            setProfilePicture(URL.createObjectURL(file));
            setProfileFile(file)
        }
    };

    const changeMainImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a URL for the selected file
            setMainCard(URL.createObjectURL(file));
            setDisplayMainCard(URL.createObjectURL(file));
            setMainCardfile(file)
        }
    };

    const changeMainFcard1 = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a URL for the selected file
            setFacecard1(URL.createObjectURL(file));
            setFacecard1file(file)
        }
    };

    const changeMainFcard2 = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a URL for the selected file
            setFacecard2(URL.createObjectURL(file));
            setFacecard2file(file)
        }
    };


    const handleGenderChange = () => {
        setGenderSelected(genderSelected => !genderSelected);
    }

    const selectGender = (gender) => {
        const index = 0; // Replace with the correct index if you're updating a specific user entry
        handleChange(index, 'user_gender', gender);
        setGenderSelected(false); // Close the gender dropdown after selection
    };

    const updataUser = () => {
        const authToken = Cookies.get('authToken');
        const userData = userInfo[0];
        const initialdata = JSON.parse(initialData)
        
        const isDataUnchanged = JSON.stringify(userData) === JSON.stringify(initialdata[0]);
        
        
        const socialLinks = {
            customerID: userData.user_customerID,
            facebook: userData.user_participant_facebook,
            instagram: userData.user_participant_instagram,
            tiktok: userData.user_participant_tiktok,
        }
        

        if (!isDataUnchanged) {
            setFlowerLoader(true)
            axios
                .post(`${apiBaseURL}/update-user-data`, { userData }, {
                    headers: {
                    'Authorization': `Bearer ${authToken}`
                    },
                })
                .then((response) => {
                    setInitialData(JSON.stringify(userData))
                    if (response.data.success === true) {
                        if (userData.user_participant_facebook && userData.user_participant_instagram && userData.user_participant_tiktok) {
                            
                            axios
                                .post(`${apiBaseURL}/update-social-data`, { socialLinks },{
                                    headers: {
                                        'Authorization' : `Bearer ${authToken}`
                                    }
                                })
                                .then((response) => {
                                    if (response.data.success === true) {
                                        setInitialData(JSON.stringify(userData))
                                        clearErrorTimeout();
                                        timeoutId = setTimeout(() => {
                                            setFlowerLoader(false);
                                        }, 3000);
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                    
                                })
                        } else {
                            clearErrorTimeout();
                            timeoutId = setTimeout(() => {
                                setFlowerLoader(false);
                            }, 3000);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setHandleError('no Data Changed')
            setError(true)
            clearErrorTimeout();
            timeoutId = setTimeout(() => {
                setError(false);
            }, 2000);
        }
    };
    
    
    const updateImgs = () => {
        const authToken = Cookies.get('authToken');
        const userData = userInfo[0];
        
        const initialdata = JSON.parse(initialData)
        
        const isDataUnchanged = JSON.stringify(userData) === JSON.stringify(initialdata[0]);


        if (!isDataUnchanged) {
        } else {
            setHandleError('no Data Changed')
            setError(true)
            clearErrorTimeout();
            timeoutId = setTimeout(() => {
                setError(false);
            }, 2000);
        }
        
        if (profileFile) {
            const profileData = new FormData();
            profileData.append("customerID", userData.user_customerID)
            profileData.append("profilePic", profileFile)
            try {
                axios
                    .post(`${apiBaseURL}/upload-profile-picture`, profileData, {
                        headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${authToken}`
                        },
                    })
                    .then((response) => {
                        console.log(response.data);
                        
                    })
                    .catch((error) => {
                        console.log(error);
                        
                    })
            } catch (error) {
                console.log(error);
                
            }
        } 
        if (coverFile) {
            const coverData = new FormData();
            coverData.append("customerID", userData.user_customerID)
            coverData.append("coverPhoto", coverFile)
            try {
                axios
                .post(`${apiBaseURL}/upload-cover-photo`, coverData, {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${authToken}`
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    
                })
                .catch((error) => {
                    console.log(error);
                    
                })
            } catch (error) {
                console.log(error);
                
            }
        } 
        if (mainCardfile && faceCard1file && faceCard2file) {
            const images = new FormData();
            
            // Append images to FormData
            const imagesData = {
              cardImage: mainCardfile,
              faceCard1: faceCard1file,
              faceCard2: faceCard2file,
            };
          
            // Append each image file individually
            for (let key in imagesData) {
              images.append(key, imagesData[key]);
            }
            images.append("customerID", userData.user_customerID)
            // Send the form data to the backend
            axios
              .post(`${apiBaseURL}/upload-facecard-picture`, images, {
                headers: {
                  "Content-Type": "multipart/form-data", // Important for file uploads
                  'Authorization': `Bearer ${authToken}`, // Authorization token if needed
                },
              })
              .then((response) => {
                console.log(response.data); // Handle success
              })
              .catch((error) => {
                console.log(error.response.data); // Handle error
              });
        }
        if ((!mainCardfile && !faceCard1file && !faceCard2file) || (mainCardfile && !faceCard1file && !faceCard2file) || (!mainCardfile && faceCard1file && !faceCard2file) || (!mainCardfile && !faceCard1file && faceCard2file)) {
            setHandleError('Complete all the image fields')
            setError(true)
            clearErrorTimeout();
            timeoutId = setTimeout(() => {
                setError(false);
            }, 2000);
        } else if ((!profileFile && !coverFile && !mainCardfile) || (!profileFile && !coverFile && !faceCard1file) || (!profileFile && !coverFile && !faceCard2file)) {
            
            setHandleError('no Data Changed')
            setError(true)
            clearErrorTimeout();
            timeoutId = setTimeout(() => {
                setError(false);
            }, 2000);
        } else {
            setError(false)
            setFlowerLoader(true)
            clearErrorTimeout();
            timeoutId = setTimeout(() => {
                setFlowerLoader(false);
            }, 3000);
        }
    }
    
    const updateDescription = () => {
        const authToken = Cookies.get('authToken');
        const userData = userInfo[0];
        const initialdata = JSON.parse(initialData)
        
        const isDataUnchanged = JSON.stringify(userData) === JSON.stringify(initialdata[0]);
        if (!isDataUnchanged) {
            setFlowerLoader(true)
            axios
                .post(`${apiBaseURL}/update-user-data`, { userData }, {
                    headers: {
                    'Authorization': `Bearer ${authToken}`
                    },
                })
                .then((response) => {
                    setInitialData(JSON.stringify(userData))
                    clearErrorTimeout();
                    timeoutId = setTimeout(() => {
                        setFinishedForm(true);
                    }, 3000);
                    timeoutId = setTimeout(() => {
                        setOnConfetti(false);
                    }, 7000);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setHandleError('no Data Changed')
            setError(true)
            clearErrorTimeout();
            timeoutId = setTimeout(() => {
                setError(false);
            }, 2000);
        }
    }

    const closeRegister = () => {
        setFinishedForm(false)
        setOpenParticipantForm(false)
    }

  return (
    <div className={`registerParticipant ${openParticipanForm}`}>
        <>{flowerLoader ? 
            <>
                {finishedForm ?
                    <>
                        <div className="form-finished">
                            <div className={`finished-container ${onConfetti}`}>
                                <Confetti width={window.innerWidth} height={window.innerHeight} />
                            </div>
                            <div className="finished-continue">
                                <h1>Thank You for Your Submission!</h1>
                                <p>We appreciate you taking the time to complete the form.</p>
                                <p>Our team is reviewing your submission and will keep you updated. You’ll hear from us soon!</p>
                                <div className="cntinue-btn">
                                    <button onClick={closeRegister}>Done</button>
                                </div>
                            </div>

                        </div>
                    </> :
                    <div className="flowerLoading">
                        <FlowerLoader/>
                    </div> 
                }
            </>
            :
            <>
                {handleError !== '' &&(
                    <div className={`erReg-handling ${error}`}>
                        <p>{handleError}</p>
                    </div>
                )}
                <FetchUser setCurrentUser={setCurrentUser} setUserInfo={setUserInfo} setInitialData={setInitialData}/>
                <div className="rgtrpr-container">
                    <div className="rgtrpr-header">
                        <h1>Skinmiso Partnership Form</h1>
                        <p>Shine Brighter at Skinmiso</p>
                    </div>
                    <hr />
                    <div className="rgtrpr-content">
                        <section className={`rgtrprSec-first ${formPage}`}>
                            <h1><FaInfoCircle id='rgSecIcon'/> Important: Participant Agreement</h1>
                            <p>Please review the participant agreement carefully. This document outlines the program requirements, including guidelines on engagement, social media, promotion, rewards, and code of conduct. Ensure you understand and agree before proceeding..</p>
                            <br />
                            <p>Please Read the Aggreement form before you proceed</p>
                            <ParticipantAggreement setUserAgree={setUserAgree} userAgree={userAgree}/>
                        </section>
                        <section className={`rgtrprSec-second ${formPage}`}>
                            {!userLog &&(
                                <>
                                    <div className="userNolog">
                                        <div className="userNolog-img">
                                            <img src={noUser} alt="" />
                                        </div>
                                        <div className="userNolog-content">
                                            <h1>User Not Found</h1>
                                            <hr />
                                            <p>It seems like you haven't logged in or registered yet. Please log in to continue.</p>
                                        </div>
                                    </div>
                                    <div className="reg-lgnBtns">
                                        <button onClick={openlogin}>Login</button>
                                    </div>
                                </>
                            )}
                            {userLog && userInfo &&(
                                <div className="rgstrPar-prsnl-info">
                                    <h2><PiStarFourBold id='rgstrStarIcon'/> Let’s Get Started</h2>
                                    <p>Help us know you better by answering a few questions and completing the form.</p>
                                    {userInfo.length > 0 &&(
                                        <>
                                            {userInfo.map((item, index) => (
                                                <div className="rgstrPr-Container" key={item.user_id || index}>
                                                    <div className="rgstrPr-head">
                                                        <h1><RiFileUserLine id='rgstrPrIcon'/>Personal Information</h1>
                                                        <p>Please provide basic information about yourself. This helps us personalize your experience.</p>
                                                    </div>
                                                    <div className="rgstrPr-content">
                                                        <ul>
                                                            <li>
                                                                <input
                                                                    type="text"
                                                                    id="firstname"
                                                                    placeholder="Skinmiso"
                                                                    value={item.user_first_name || ''}
                                                                    onChange={(e) => handleChange(index, 'user_first_name', e.target.value)}
                                                                />
                                                                <label htmlFor="firstname">First Name</label>
                                                            </li>
                                                            <li>
                                                                <input
                                                                    type="text"
                                                                    id="lastname"
                                                                    placeholder="Canada"
                                                                    value={item.user_last_name || ''}
                                                                    onChange={(e) => handleChange(index, 'user_last_name', e.target.value)}
                                                                />
                                                                <label htmlFor="lastname">Last Name</label>
                                                            </li>
                                                        </ul>
                                                        <ul>
                                                            <li>   
                                                                <button onClick={handleGenderChange} id='gender'>Gender</button>
                                                                <span onClick={handleGenderChange}>
                                                                    {item.user_gender || genderSelect || ""}
                                                                </span>
                                                                <label className={`gndrLabel ${genderSelect !== '' || item.user_gender !== ''}`}>Gender</label>
                                                                <div className={`gndrChoice ${genderSelected}`}>
                                                                    <p onClick={() => selectGender('Male')}>Male</p>
                                                                    <p onClick={() => selectGender('Female')}>Female</p>
                                                                    <p onClick={() => selectGender('LGBTQIA+')}>LGBTQIA+</p>
                                                                    <p onClick={() => selectGender('Rather not say')}>Rather not say</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <input
                                                                    type="date"
                                                                    className={item.user_birthday ? 'has-value' : ''}
                                                                    id="birthdate"
                                                                    placeholder="birthday"
                                                                    value={item.user_birthday || ''}
                                                                    onChange={(e) => handleChange(index, 'user_birthday', e.target.value)}
                                                                />
                                                                <label htmlFor="birthdate">Birthday</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="rgstrPr-head">
                                                        <h1><RiFileUserLine id='rgstrPrIcon'/>Contact Information</h1>
                                                        <p>Provide your email address and phone number to help us stay connected.</p>
                                                    </div>
                                                    <div className="rgstrPr-content">
                                                        <ul>
                                                            <li>
                                                                <input
                                                                    type="text"
                                                                    id="email"
                                                                    placeholder="Canada"
                                                                    value={item.user_email || ''}
                                                                    onChange={(e) => handleChange(index, 'user_email', e.target.value)}
                                                                />
                                                                <label htmlFor="email">Email Address</label>
                                                            </li>
                                                            <li>
                                                                <input
                                                                    type="text"
                                                                    id="mobilno"
                                                                    placeholder="+123"
                                                                    value={item.user_mobileno || ''}
                                                                    onChange={(e) => handleChange(index, 'user_mobileno', e.target.value)}
                                                                />
                                                                <label htmlFor="mobilno">Mobile Number</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="rgstrPr-head">
                                                        <h1><FaCameraRetro id='rgstrPrIcon'/>Social Media Links</h1>
                                                        <p>Connect your social media profiles to enhance your networking opportunities within the program.</p>
                                                    </div>
                                                    <div className="rgstrPr-content">
                                                        <ul>
                                                            <li>
                                                                <input
                                                                    type="text"
                                                                    id="facebook"
                                                                    placeholder="facebook"
                                                                    value={item.user_participant_facebook || ''}
                                                                    onChange={(e) => handleChange(index, 'user_participant_facebook', e.target.value)}
                                                                />
                                                                <label htmlFor="facebook"><FaFacebook id='sLinksIcons'/>Facebook</label>
                                                            </li>
                                                            <li>
                                                                <input
                                                                    type="text"
                                                                    id="instagram"
                                                                    placeholder="instagram"
                                                                    value={item.user_participant_instagram || ''}
                                                                    onChange={(e) => handleChange(index, 'user_participant_instagram', e.target.value)}
                                                                />
                                                                <label htmlFor="instagram"><FaInstagram id='sLinksIcons'/>Instagram</label>
                                                            </li>
                                                            <li>
                                                                <input
                                                                    type="text"
                                                                    id="tiktok"
                                                                    placeholder="tiktok"
                                                                    value={item.user_participant_tiktok || ''}
                                                                    onChange={(e) => handleChange(index, 'user_participant_tiktok', e.target.value)}
                                                                />
                                                                <label htmlFor="tiktok"><FaTiktok id='sLinksIcons'/>Tiktok</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="sbmitInfo-btn">
                                                        <button onClick={updataUser}>Submit Form</button>
                                                    </div>
                                                    <h4>Note: Please don't leave the fields blank. If the information was already completed, you do not need to submit the form.</h4>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            )}
                        </section>
                        <section className={`rgtrprSec-third ${formPage}`}>
                            {userLog && userInfo &&(
                                <div className="rgstrPar-prsnl-info">
                                    <h2><TbPhotoEdit id='rgstrStarIcon'/> Personalize Your Profile</h2>
                                    <p>Make your profile stand out by setting a custom cover photo, profile picture, and facecards.</p>
                                    {userInfo.length > 0 &&(
                                        <>
                                        {userInfo.map((item, index) => (
                                            <div className="prtcpnt-imgs-container" key={item}>
                                                <div className="prtcpnt-imgs">
                                                    <p>Customize your Profile images from Cover photo to Facecards</p>
                                                    <div className="prtcpnt-cover-set">
                                                        {coverFile ?
                                                            <img src={coverPhoto} alt="" /> :
                                                            <img src={item.user_cover_photo !== '' ? `https://2wave.io/skinmiso/customer_cover_photo/${item.user_cover_photo}` : defaultCover} alt="" />
                                                        }
                                                    </div>
                                                    <div className="prtcpnt-edit-imgs cover" >
                                                        <span><FiEdit id='editPhotoIcon'/>edit cover photo</span>
                                                        <input type="file" onChange={changeCover}/>
                                                    </div>
                                                    <div className="prtcpnt-edit-imgs profile">
                                                        <span><FiEdit id='editPhotoIcon'/>edit profile photo</span>
                                                        <input type="file" onChange={changeProfile}/>
                                                    </div>
                                                    <div className="prtcpnt-prfpic">
                                                        <div className="prtcpnt-profile-set">
                                                            {profileFile ?
                                                                <img src={profilePicture} alt="" /> :
                                                                <img src={item.user_profile_pic !== '' ? `https://2wave.io/skinmiso/customer_profile_picture/${item.user_profile_pic}` : defaultProfile} alt="" />
                                                            }
                                                        </div>
                                                        <div className={`prtcpnt-active-status ${item.user_activity}`}></div>
                                                    </div>
                                                </div>
                                                <div className="prtcpnt-usr-dts">
                                                    <input type="text" value={item.user_username} onChange={(e) => handleChangeUsername(index, 'user_username', e.target.value)}/>
                                                    <p>Your Username</p>
                                                </div>
                                                <div className="prtcpnt-facecard">
                                                    <span>
                                                        {mainCardfile ?
                                                            <img src={mainCard} alt="" /> :
                                                            <img src={item.user_participant_card_img !== '' ? `https://2wave.io/skinmiso/customer_facecards/${item.user_participant_card_img}` : mainCard} alt="" />
                                                        }
                                                        <input type="file" onChange={changeMainImage}/>
                                                    </span>
                                                    <span>
                                                        {faceCard1file ?
                                                            <img src={faceCard1} alt="" /> :
                                                            <img src={item.user_participant_facecard_1 !== '' ? `https://2wave.io/skinmiso/customer_facecards/${item.user_participant_facecard_1}` : mainCard} alt="" />
                                                        }
                                                        <input type="file" onChange={changeMainFcard1}/>
                                                    </span>
                                                    <span>
                                                        {faceCard2file ?
                                                            <img src={faceCard2} alt="" /> :
                                                            <img src={item.user_participant_facecard_2 !== '' ? `https://2wave.io/skinmiso/customer_facecards/${item.user_participant_facecard_2}` : defaultProfile} alt="" />
                                                        }
                                                        <input type="file" onChange={changeMainFcard2}/>
                                                    </span>
                                                </div>
                                                <p id='prtcpnt-img-note'>Note: If the image fields were left to default, they would automatically register as your profile images. If the images was not loaded properly, click the button again to display</p>
                                                <div className="sbmitInfo-btn">
                                                    <button onClick={updateImgs}>Update Profile</button>
                                                </div>
                                            </div>
                                        ))}
                                        </>
                                    )}
                                </div>
                            )}
                        </section>
                        <section className={`rgtrprSec-fourth ${formPage}`}>
                            {userLog && userInfo &&(
                                <div className="rgstrPar-prsnl-info">
                                    <h2><RiStarSmileFill id='rgstrStarIcon'/> Express Yourself</h2>
                                    <p>Share your dreams and goals with the community, and introduce yourself to others.</p>
                                    {userInfo.length > 0 &&(
                                        <>
                                        {userInfo.map((item, index) => (
                                            <div className="prtcpnt-desc-container" key={item}>
                                                <div className="prtcpnt-desc-header">
                                                    <h1><PiMagicWandFill id='rgstrPrIcon' /> What's your Talent?</h1>
                                                    <p>Your talent makes you unique.</p>
                                                </div>
                                                <div className="prtcpnt-desc-contents">
                                                    <input 
                                                        type="text" 
                                                        placeholder="e.g., Singer..."
                                                        value={item.user_participant_talent || ''}
                                                        onChange={(e) => handleChange(index, 'user_participant_talent', e.target.value)} 
                                                    />
                                                </div>
                                                <div className="prtcpnt-desc-header">
                                                    <h1><PiMagicWandFill id='rgstrPrIcon' /> What's your Profession?</h1>
                                                    <p>Your profession shapes how others see you.</p>
                                                </div>
                                                <div className="prtcpnt-desc-contents">
                                                    <input 
                                                        type="text" 
                                                        placeholder="e.g., Doctor..." 
                                                        value={item.user_participant_profession || ''}
                                                        onChange={(e) => handleChange(index, 'user_participant_profession', e.target.value)} 
                                                    />
                                                </div>

                                                <div className="prtcpnt-desc-header">
                                                    <h1><FaCloud id='rgstrPrIcon' /> Describe your Dream and Introduce Yourself</h1>
                                                    <p>Share your dreams, goals, and passions with the community.</p>
                                                </div>
                                                <div className="prtcpnt-desc-contents">
                                                    <textarea 
                                                        placeholder="Write about your dreams, goals, and a bit about yourself..." 
                                                        value={item.user_participant_description || ''}
                                                        onChange={(e) => handleChange(index, 'user_participant_description', e.target.value)} 
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="finish-stup">
                                <span className="mas">Finish Setup</span>
                                <button type="button" onClick={updateDescription}>Finish Setup</button>
                            </div>
                        </section>
                    </div>
                    {formPage === 'first' && userAgree &&(
                        <div className="regPart-proceedBtn">
                            <button onClick={proceedPage}>Next</button>
                        </div>
                    )}
                {(formPage === 'second' || formPage === 'third') && userInfo &&(
                        <>
                            {userInfo.length > 0 && (
                                <div className="regPart-proceedBtn imgs">
                                    <button onClick={proceedPage}>Next</button>
                                </div>
                            )}
                        </>
                    )}
                    {(formPage === 'second' || formPage === 'third' || formPage === 'fourth') && (
                        <>
                            <div className="regPart-bck-btn">
                                <button onClick={previousPage}><IoCaretBack id='bckIcon'/>Back</button>
                            </div>
                        </>
                    )}
                </div>
                <div className="rgtrpr-imgBg">
                    <div className="rgtrpr-img-content">
                        <h1>{displayMainCard && userInfo ? 
                            <>
                                {userInfo[0].user_first_name}{` ${userInfo[0].user_last_name}`}                         
                            </> :
                            "Hyunseok Aries"
                        }</h1>
                        <p>{displayMainCard && userInfo ? 
                            "New Participant"
                            :
                            "Top Skinmiso Beauty"
                        }</p>
                        <div className="rgtpr-img-socLinks">
                            <FaFacebook id='rgtpr-img-SocIcon'/>
                            <FaInstagram id='rgtpr-img-SocIcon'/>
                            <FaTiktok  id='rgtpr-img-SocIcon'/>
                            <FaYoutube id='rgtpr-img-SocIcon'/>
                        </div>
                    </div>
                    {displayMainCard && userInfo ? 
                        <img src={userInfo[0].user_participant_card_img ? `https://2wave.io/skinmiso/customer_facecards/${userInfo[0].user_participant_card_img}` : displayMainCard} alt="" /> :
                        <img src={samplePic} alt="" />
                    }
                </div>
            </>
            }
        </>
    </div>
  )
}

export default RegisterParticipant