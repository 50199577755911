import React,{useState} from 'react'
import axios from 'axios';



const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

const Admin = () => {
    const [prdname, setPrdname] = useState(null);
    const [prdtype, setPrdtype] = useState(null);
    const [prdprice, setPrdprice] = useState(null);
    const [prdSize, setPrdsize] = useState(null);
    const [prdsupply, setPrdsupply] = useState(null);
    const [prdbrand, setPrdbrand] = useState(null);
    const [prdshortDescription, setPrdshortDescription] = useState(null);
    const [prdlongDescription, setPrdlongtDescription] = useState(null);
    const [prdShortHeader, setPrdShortHeader] = useState(null);
    const [prdShortInfo, setPrdShortInfo] = useState(null);
    const [prdTags, setPrdTags] = useState([]);
    const [inputTag, setInputTag] = useState(''); // To handle input
    const [prdBenefitsHeader, setPrdBenefitsHeader] = useState(null);
    const [prdBenefits, setPrdBenefits] = useState([]);
    const [inputPrdBenefits, setInputPrdBenefits] = useState(''); // To handle input
  
    const [prdMainVid, setPrdMainVid] = useState(null);
  
    const [prdShortlinks, setPrdShortLinks] = useState([{ shortsNumber: 1, link: '' }]);
    const [prdIngredients, setprdIngredients] = useState([{ ingredient: '', info: '' }]);

  
    // For handling file inputs
    const [prdImages, setPrdImages] = useState({
      productImg: null,
      productImg1: null,
      productImg2: null,
      productImg3: null,
      productImg4: null,
      benefitsImg: null,
      ingredientsImg: null,
      banner: null,
    });
  
    // Handle file selection
    const handleFileChange = (e, type) => {
      setPrdImages({
        ...prdImages,
        [type]: e.target.files[0],
      });
    };
  
    // Function to add a tag
    const handleAddTag = (e) => {
      if (e.key === 'Enter' && inputTag.trim() !== '') {
        setPrdTags([...prdTags, inputTag.trim()]); // Add tag to array
        setInputTag(''); // Clear the input field
      }
    };

    
    const handleAddBenefits = (e) => {
        if (e.key === 'Enter' && inputPrdBenefits.trim() !== '') {
          setPrdBenefits([...prdBenefits, inputPrdBenefits.trim()]); // Add tag to array
          setInputPrdBenefits(''); // Clear the input field
        }
      };

    // Function to update the short link
    const handleShortlinkChange = (index, value) => {
      const updatedShortlinks = prdShortlinks.map((short, i) => {
        if (i === index) {
          return { ...short, link: value };
        }
        return short;
      });
      setPrdShortLinks(updatedShortlinks);
    };
  
    // Function to add a new short link
    const handleAddShort = () => {
      setPrdShortLinks([
        ...prdShortlinks,
        { shortsNumber: prdShortlinks.length + 1, link: '' }, // Add new short link
      ]);
    };


    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        const updatedIngredients = [...prdIngredients];
        updatedIngredients[index][name] = value;
        setprdIngredients(updatedIngredients);
    };

    const handleAddIngredient = () => {
        setprdIngredients([...prdIngredients, { ingredient: '', info: '' }]);
    };
  
    // Submit function to gather all data and send a POST request
    const handleSubmit = async () => {
      const formData = new FormData();
      
      formData.append('productName', prdname);
      formData.append('productType', prdtype);
      formData.append('productPrice', prdprice);
      formData.append('productSize', prdSize);
      formData.append('productSupply', prdsupply);
      formData.append('productBrand', prdbrand);
      formData.append('productShortDescription', prdshortDescription);
      formData.append('productLongDescription', prdlongDescription);
      formData.append('productMainVideo', prdMainVid);
      formData.append('prdShortHead', prdShortHeader);
      formData.append('prdShortInfo', prdShortInfo);
      
      // Append tags
      formData.append('prdtags', prdTags)
      formData.append('prdBenefitsHead', prdBenefitsHeader)
      formData.append('prdBenefits', prdBenefits)
  
      // Append images
      Object.keys(prdImages).forEach((key) => {
        if (prdImages[key]) {
          formData.append(key, prdImages[key]);
        }
      });
  
      // Append steps
      formData.append('prdIngredients', JSON.stringify(prdIngredients));
  
      // Append short links
      prdShortlinks.forEach((short, index) => formData.append(`shortLinks[${index}]`, short.link));
  
      // for (let [key, value] of formData.entries()) {
      //   console.log(key, value);
      // }
      
      // Make the POST request
      try {
        const response = await axios.post(
            "http://localhost/skinmiso/product/insert_database.php",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        );
        console.log(response);
        
      } catch (error) {
        console.error('Error submitting product:', error);
      }
    };

  return (
    <div className="admin">
        <div className="main-admin">
            <div className="admin-container">
                <div className="admin-contents" style={{padding: '10vw 0'}}>
                <p>Product Name</p>
            <input type="text" value={prdname} onChange={(e) => setPrdname(e.target.value)} />

            <p>Product Type</p>
            <input type="text" value={prdtype} onChange={(e) => setPrdtype(e.target.value)} />

            <p>Product Size</p>
            <input type="text" value={prdSize} onChange={(e) => setPrdsize(e.target.value)} />

            <p>Product Price</p>
            <input type="number" value={prdprice} onChange={(e) => setPrdprice(e.target.value)} />

            <p>Product Supply</p>
            <input type="number" value={prdsupply} onChange={(e) => setPrdsupply(e.target.value)} />

            <p>Product Brand</p>
            <input type="text" value={prdbrand} onChange={(e) => setPrdbrand(e.target.value)} />

            <p>Product Long Description</p>
            <input type="text" value={prdlongDescription} onChange={(e) => setPrdlongtDescription(e.target.value)} />

            <p>Product Short Description</p>
            <input type="text" value={prdshortDescription} onChange={(e) => setPrdshortDescription(e.target.value)} />

            <p>Product Tags</p>
            {prdTags.map((tag, i) => (
              <div key={i}>{tag}</div>
            ))}
            <input
              type="text"
              value={inputTag}
              onChange={(e) => setInputTag(e.target.value)}
              onKeyDown={handleAddTag}
              placeholder="Enter a tag and press Enter"
            />
            
            <p>Product benefits Headers</p>
            <input type="text" value={prdBenefitsHeader} onChange={(e) => setPrdBenefitsHeader(e.target.value)} />

            <p>Product benefits</p>
            {prdBenefits.map((benefits, i) => (
              <div key={i}>{benefits}</div>
            ))}
            <input
              type="text"
              value={inputPrdBenefits}
              onChange={(e) => setInputPrdBenefits(e.target.value)}
              onKeyDown={handleAddBenefits}
              placeholder="Enter a benefits and press Enter"
            />

            <p>Product Main Video</p>
            <input type="text" value={prdMainVid} onChange={(e) => setPrdMainVid(e.target.value)} />

            {/* Image Inputs */}
            <p>Product Images</p>
            <p>main img</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'productImg')} />
            <p>product img 1</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'productImg1')} />
            <p>product img 2</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'productImg2')} />
            <p>product img 3</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'productImg3')} />
            <p>product img 4</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'productImg4')} />
            <p>benefits img</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'benefitsImg')} />
            <p>ingredients img</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'ingredientsImg')} />
            <p>banner</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'banner')} />

            <p>Product Short Links</p>
            {prdShortlinks.map((short, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <label>Short {short.shortsNumber}:</label>
                <input
                  type="text"
                  value={short.link}
                  onChange={(e) => handleShortlinkChange(index, e.target.value)}
                  placeholder={`Enter short link ${short.shortsNumber}`}
                />
              </div>
            ))}
            <button onClick={handleAddShort}>Add Short Link</button>

            <p>Input ingredients</p>
            {prdIngredients.map((prd, index) => (
                <div key={index}>
                <p>Ingredient</p>
                <input
                    type="text"
                    name="ingredient"
                    value={prd.ingredient}
                    onChange={(e) => handleInputChange(index, e)}
                />
                <p>Ingredient Info</p>
                <input
                    type="text"
                    name="info"
                    value={prd.info}
                    onChange={(e) => handleInputChange(index, e)}
                />
                </div>
            ))}
            <button onClick={handleAddIngredient}>Add Ingredient</button>

            <p>Product Shorts header</p>
            <input type="text" value={prdShortHeader} onChange={(e) => setPrdShortHeader(e.target.value)} />
            <p>Product Shorts info</p>
            <input type="text" value={prdShortInfo} onChange={(e) => setPrdShortInfo(e.target.value)} />
            <button onClick={handleSubmit}>Submit Product</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Admin