import React,{useState,useEffect} from "react";
// css
import '../src/App.css'
// router
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { GoogleOAuthProvider } from "@react-oauth/google";
// js 
import Landing from "./components/pages/landing";
import Nav from "./components/nav";
import Footer from "./components/footer";
import Porecare from "./components/pages/porecare";
import VitaminC from "./components/pages/vitaminC";
import Product from "./components/pages/product";
import Admin from "./components/pages/admin/admin";
import PrivacyPolicy from "./components/pages/privacyPoilicy";
import TermsCondition from "./components/pages/temsCondition";
import HelpCenter from "./components/pages/help";
import CampaignPage from "./components/pages/campaign";
import Allproducts from "./components/pages/allProducts";
import Profile from "./components/pages/profile";
import FlowerLoader from "./components/pages/loader/flower";

const googleID = process.env.REACT_APP_GOOGLE_ID

function App() {
  

  const [openParticipanForm,setOpenParticipantForm] = useState(false)

  // loader
  const [mainflowerLoader,setFlowerLoader] = useState(true) 


  let timeoutID
  
  const clearErrorTimeout = () => {
    clearTimeout(timeoutID); // Clear the timeout
  };

  useEffect(() => {
    
  clearErrorTimeout();
  timeoutID = setTimeout(() => {
    setFlowerLoader(false);
  }, 3000);
  }, [])
  

  return (
    <GoogleOAuthProvider clientId={googleID}>
      <Router>
        <div className={`App ${mainflowerLoader}`}>
          {mainflowerLoader &&(
            <div className="app-mainLoader">
              <FlowerLoader/>
            </div>
          )}
          <Nav setOpenParticipantForm={setOpenParticipantForm} openParticipanForm={openParticipanForm} setFlowerLoader={setFlowerLoader}/>
          <Routes>
            {/* main pages */}
            <Route path="/" element={<><Landing/></>}/>
            <Route path="/porecare" element={<><Porecare/></>}/>
            <Route path="/vitaminC" element={<><VitaminC/></>}/>
            <Route path="/glow-together" element={<><CampaignPage setOpenParticipantForm={setOpenParticipantForm}/></>}/>

            {/* web data */}
            <Route path="/privacypolicy" element={<><PrivacyPolicy/></>}/>
            <Route path="/termscondition" element={<><TermsCondition/></>}/>
            <Route path="/help-center" element={<><HelpCenter/></>}/>

            {/* products */}
            <Route path="/product/:product" element={<><Product/></>}/>
            <Route path="/all-products" element={<><Allproducts/></>}/>

            {/* panels */}
            <Route path="/admin" element={<><Admin/></>}/>

            {/* users */}
            <Route path="/:user" element={<><Profile/></>}/>
          </Routes>
          <Footer/>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
