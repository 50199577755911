import React, { useState, useEffect } from 'react'
// css
import '../css/product.css'
// router dom
import { useParams } from 'react-router-dom'
// axios
import axios from 'axios'
// icons
import { FaRegHeart, FaStar, FaStarHalf } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
// assets
import sampleprod1 from '../assets/imgs/Pore Purifying Toner.png'
import sampleprod2 from '../assets/imgs/Pore Zero Night Cream.png'
import sampleprod3 from '../assets/imgs/Pure Vitamin C Serum.png'
import pplogo from '../assets/imgs/paypal.webp'
import skinmisoLogo from '../assets/imgs/skinmiso.png'

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const Product = () => {
  const { product } = useParams()
  
  const [ prdMaininfo, setPrdMaininfo  ] = useState(null)
  const [ prdTags, setPrdTags ] = useState([])
  const [ prdBenefits, setPrdBenefits ] = useState([])
  const [ prdIngredients, setPrdIngredients ] = useState([])

  const [ prdOrderQantity, setPrdOrderQuantity ] = useState(1)
  const [transformOrigin, setTransformOrigin] = useState({ x: 'center', y: 'center' });

  const [ imgLoader,setImageLoader ] = useState(true)

  // Handle mouse movement over the image
  const handleMouseMove = (e, img) => {
    const rect = img.getBoundingClientRect();
    const x = e.clientX - rect.left; // X coordinate of cursor relative to image
    const y = e.clientY - rect.top;  // Y coordinate of cursor relative to image

    setTransformOrigin({ x, y });
  };

  useEffect(() => {
    const fetchPrdinfo = async () => {
      const productName = product.replace(/-/g, ' ')
      
      try {
        axios
        .post(`${apiBaseURL}/product-info`,{productName})
        .then((response) => {
          const productdata = response.data
          
          if (productdata) {
            setPrdMaininfo(productdata)
            
            const tags = productdata.info.sm_product_tags
            const benefits = productdata.info.sm_product_benefits
            const ingredients = productdata.info.sm_product_ingredients
            
            const jsonparseIng = JSON.parse(ingredients)
            setPrdIngredients(jsonparseIng)
            setPrdTags(tags.split(','))
            setPrdBenefits(benefits.split(','))

          } else {
            console.log('no data fetch');
          }
        })
        .catch((error) => {
          console.log(error);
        })
      } catch (error) {
        console.log(error);
        
      }
    }
    fetchPrdinfo()
  }, [product])

  
  const addQuantity = () =>{
    setPrdOrderQuantity(prdOrderQantity + 1)
  }
  const lessQuantity = () =>{
    if (prdOrderQantity > 1) {
      setPrdOrderQuantity(prdOrderQantity - 1);
    }
  }

  return (
    <>
      <div className="product-page">
        <div className='prdMaininfo'>
          {prdMaininfo &&(
            <div className="prdContainer">
              <div className="prdHeader">
                <div className="prdHead-imgs">
                  <div className="prdImgs-blk">
                    <ul>
                      <li>
                        <img
                          src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_img1}`}
                          alt=""
                          style={{ transformOrigin: `${transformOrigin.x}px ${transformOrigin.y}px` }}
                          onMouseMove={(e) => handleMouseMove(e, e.target)} // Mouse move event
                        />
                      </li>
                      <li>
                        <img
                          src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_img2}`}
                          alt=""
                          style={{ transformOrigin: `${transformOrigin.x}px ${transformOrigin.y}px` }}
                          onMouseMove={(e) => handleMouseMove(e, e.target)} // Mouse move event
                        />
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <img
                          src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_img3}`}
                          alt=""
                          style={{ transformOrigin: `${transformOrigin.x}px ${transformOrigin.y}px` }}
                          onMouseMove={(e) => handleMouseMove(e, e.target)} // Mouse move event
                        />
                      </li>
                      <li>
                        <img
                          src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_img4}`}
                          alt=""
                          style={{ transformOrigin: `${transformOrigin.x}px ${transformOrigin.y}px` }}
                          onMouseMove={(e) => handleMouseMove(e, e.target)} // Mouse move event
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="prdimg-list">
                    <ul>
                      <li>
                        <img
                          src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_img1}`}
                          alt=""
                          style={{ transformOrigin: `${transformOrigin.x}px ${transformOrigin.y}px` }}
                          onMouseMove={(e) => handleMouseMove(e, e.target)} // Mouse move event
                        />
                      </li>
                      <li>
                        <img
                          src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_img2}`}
                          alt=""
                          style={{ transformOrigin: `${transformOrigin.x}px ${transformOrigin.y}px` }}
                          onMouseMove={(e) => handleMouseMove(e, e.target)} // Mouse move event
                        />
                      </li>
                      <li>
                        <img
                          src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_img3}`}
                          alt=""
                          style={{ transformOrigin: `${transformOrigin.x}px ${transformOrigin.y}px` }}
                          onMouseMove={(e) => handleMouseMove(e, e.target)} // Mouse move event
                        />
                      </li>
                      <li>
                        <img
                          src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_img4}`}
                          alt=""
                          style={{ transformOrigin: `${transformOrigin.x}px ${transformOrigin.y}px` }}
                          onMouseMove={(e) => handleMouseMove(e, e.target)} // Mouse move event
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="prdHead-info">
                  <h1>{prdMaininfo.info.sm_product_brand}</h1>
                  <h2>{prdMaininfo.sm_product_name}</h2>
                  <span>
                    <div className="prhStrrer">
                      <FaStar id='prhStr'/>
                      <FaStar id='prhStr'/>
                      <FaStar id='prhStr'/>
                      <FaStar id='prhStr'/>
                      <FaStarHalf id='prhStr'/>
                    </div> 
                    <p>21,354 Reviews</p>
                  </span>
                  <h3>${prdMaininfo.info.sm_product_price} <p>({prdMaininfo.info.sm_product_size})</p></h3>
                  <div className="prdTags">
                  <ul>
                    {prdTags.map((tags,i) => (
                      <li key={i}>{tags}</li>
                    ))}
                  </ul>
                  </div>
                  <h4>{prdMaininfo.info.sm_product_long_description}</h4>
                  <div className="prdbtns">
                    <div className="prdqty">
                      <section>
                        <p id='prdlss' onClick={lessQuantity}>-</p>
                        <input type="number" min={1} max={10} value={prdOrderQantity} onChange={(e) => setPrdOrderQuantity(e.target.value)}/>
                        <p id='prdAdd' onClick={addQuantity}>+</p>
                      </section>
                    </div>
                    <button>Add to Cart</button>
                  </div>
                  <div className="prd-drCckt">
                    <div className="addmrprd">
                      <p className='addtfv'><FaRegHeart id='prdaddFav'/>Add to wishlist <span></span></p>
                      <p className='prdmpm'>more payment option</p>
                    </div>
                    <button>Buy using <img src={pplogo} alt="" /></button>
                  </div>
                  <div className="prd-by-in-amazon">
                    <button>Buy in amazon</button>
                  </div>
                </div>
              </div>
              <div className="prdContents">
                <div className="prd-shrts">
                  <h1>{prdMaininfo.shorts.sm_shorts_header}</h1>
                  <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                  <p>{prdMaininfo.shorts.sm_shorts_info}</p>
                </div>
              </div>
              <div className="prdinfoContents">
                <section>
                  <div className="prd-main-desc">
                    <div className="prdmD-img">
                      <div className="prdmd-imgContent">
                        <img 
                          src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_img_main}`}
                          alt="" 
                        />
                      </div>
                    </div>
                    <div className="prdmD-info">
                      <span>
                        <h1>{prdMaininfo.sm_product_name}</h1>
                        <p>{prdMaininfo.info.sm_product_short_description}</p>
                      </span>
                    </div>
                  </div>
                </section>
                <section>
                  <div className="prd-main-desc">
                    <div className="prdmD-benefits">
                      <span>
                        <h1>{prdMaininfo.info.sm_product_benefits_header}</h1>
                        <ul>
                          {prdBenefits.map((benefits,i) => (
                            <li><IoMdCheckmarkCircleOutline id='prdchck' key={i}/>{benefits}</li>
                          ))}
                        </ul>
                      </span>
                    </div>
                    <div className="prdmD-img">
                      <div className="prdmd-imgContent">
                        <img 
                          src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_benefits}`}
                          alt="" 
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className="prd-main-desc">
                    <div className="prdmD-img">
                      <div className="prdmd-imgContent">
                        <img 
                          src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_ingredients}`}
                          alt="" 
                        />
                      </div>
                    </div>
                    <div className="prdmD-ingr">
                      <span>
                        <h1>Key Ingredients</h1>
                        <ul>
                          {prdIngredients.map((ingredients,i) => (
                            <div key={i}>
                              <li><IoMdCheckmarkCircleOutline id='prdchck'/>{ingredients.ingredient}</li>
                              <p>{ingredients.info}</p>
                            </div>
                          ))}
                        </ul>
                      </span>
                    </div>
                  </div>
                </section>
                <section>
                  <div className="prdMain-vid">
                    <div className="prdVid">
                      <iframe src={prdMaininfo.info.sm_product_main_video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                  </div>
                </section>
                <section>
                  <div className="prd-fllbanner">
                    <img 
                      src={imgLoader? skinmisoLogo : `https://2wave.io/skinmiso/${prdMaininfo.images.sm_product_banner}`}
                      alt="" 
                    />
                  </div>
                </section>
                <section>
                  <div className="pcRlprd">
                      <h1>Related Products</h1>
                  </div>
                  <div className="pcRlprd-contents">
                    <ul>
                      <li>
                          <span>Best</span>
                          <img src={sampleprod1} alt="" />
                          <div className="pcPrd-info">
                              <h1>Pore Purifying Toner</h1>
                              <p>
                                  <div className="pcPrdStar">
                                      <FaStar id='pcRprdstr'/>
                                      <FaStar id='pcRprdstr'/>
                                      <FaStar id='pcRprdstr'/>
                                      <FaStar id='pcRprdstr'/>
                                      <FaStarHalf id='pcRprdstr'/>
                                  </div>
                                  (4.75)
                              </p>
                          </div>
                          <h2>$17.00</h2>
                          <h3>4,980 Reviews</h3>
                      </li>
                      <li>
                          <span>Best</span>
                          <img src={sampleprod2} alt="" />
                          <div className="pcPrd-info">
                              <h1>Pore Zero Night Cream</h1>
                              <p>
                                  <div className="pcPrdStar">
                                      <FaStar id='pcRprdstr'/>
                                      <FaStar id='pcRprdstr'/>
                                      <FaStar id='pcRprdstr'/>
                                      <FaStar id='pcRprdstr'/>
                                      <FaStarHalf id='pcRprdstr'/>
                                  </div>
                                  (4.75)
                              </p>
                          </div>
                          <h2>$17.00</h2>
                          <h3>4,980 Reviews</h3>
                      </li>
                      <li>
                          <span>Best</span>
                          <img src={sampleprod3} alt="" />
                          <div className="pcPrd-info">
                              <h1>Pure Vitamin C Serum</h1>
                              <p>
                                  <div className="pcPrdStar">
                                      <FaStar id='pcRprdstr'/>
                                      <FaStar id='pcRprdstr'/>
                                      <FaStar id='pcRprdstr'/>
                                      <FaStar id='pcRprdstr'/>
                                      <FaStarHalf id='pcRprdstr'/>
                                  </div>
                                  (4.75)
                              </p>
                          </div>
                          <h2>$17.00</h2>
                          <h3>4,980 Reviews</h3>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Product
