import React,{useState,useEffect} from 'react'
// css
import '../css/profile.css'
// cookies
import Cookies from 'js-cookie';
// axios
import axios from 'axios';
// icons
import { RiHistoryFill } from "react-icons/ri";
import { FaUser,FaFacebook,FaGoogle } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { BsClipboardDataFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { IoCopyOutline } from "react-icons/io5";
import { IoIosAddCircle,IoIosRemoveCircle,IoIosCloseCircle  } from "react-icons/io";
// assets
import defaultProfile from '../assets/imgs/defaultProfile.png'
import unavailable from '../assets/imgs/cancel.png'
import samplePrd from '../assets/imgs/pcCoreSet.jpg'
import piggy from '../assets/imgs/piggy-bank.png'
// js file
import LineChart from './chart/lineChart';
import Edituser from './modals/editUser-modal';
import SuccessCreateAcc from './handling/successCreate';
import AddressAutocomplete from './location';
import FetchUser from './datafetch/fetchUser';

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const Profile = () => {
  const [userInfo, setUserInfo] = useState()
  const [currentUser,setCurrentUser] = useState(null)
  const [userBarNav,setUserBarnav] = useState("cart")
  const [userID, setUserID] = useState(null)
  const [customerAddress,setCustomerAddress] = useState(null)

  console.log(customerAddress);
  
  const [editData,setEditData] = useState(false)

  const [createSuccess,setCreateSuccess] = useState(false)
  const [successContent,setSuccessContent] = useState('')

  const [copySuccess, setCopySuccess] = useState('');

  const [editLocation,setEditLocation] = useState(false)

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(userInfo[0].user_customerID);
      setCopySuccess('Copied!');
      // Reset message after 2 seconds
      setTimeout(() => setCopySuccess(''), 2000);
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const openEditData = () => {
    setEditData(true)
  }
  const closeEditData = () => {
    setEditData(false)
  }
  
  
  const openEditLocation = () => {
    setEditLocation(true)
  }
  const closeEditLocation = () => {
    setEditLocation(false)
  }

  const FunctionUnavailable = () => {
    return (
      <>
        <div className="fnctionUnavaible">
          <div className="fncUnavail-img">
            <img src={unavailable} alt="" />
          </div>
          <h1>Function Unavailable</h1>
          <p>Come back later for more updates</p>
        </div>
      </>
    )
  }

  const switchCart = () => {
    setUserBarnav("cart")
  }
  const switchHistory = () => {
    setUserBarnav("history")
  }
  const switchStats = () => {
    setUserBarnav("stats")
  }

  
  return (
    <div className="userProfile">
      <FetchUser setUserInfo={setUserInfo} setCurrentUser={setCurrentUser} setCustomerAddress={setCustomerAddress}/>
    {editLocation &&(
      <div className="editlocation">
        <AddressAutocomplete userID={userID} customerAddress={customerAddress} apiBaseURL={apiBaseURL} setCreateSuccess={setCreateSuccess} setSuccessContent={setSuccessContent} setEditLocation={setEditLocation}/>
        <div className="clsEdit-user" onClick={closeEditLocation}></div>
      </div>
    )}
    {editData &&(
      <div className='dataedit'>
        <Edituser defaultProfile={defaultProfile} userInfo={userInfo} setEditData={setEditData} apiBaseURL={apiBaseURL} setCreateSuccess={setCreateSuccess} setSuccessContent={setSuccessContent}/>
        <div className="clsEdit-user" onClick={closeEditData}></div>
      </div>
    )}
    {createSuccess &&(
      <div className={`sccssCrtAcc ${createSuccess}`}>
        <SuccessCreateAcc createSuccess={createSuccess} setCreateSuccess={setCreateSuccess} setSuccessContent={setSuccessContent} successContent={successContent}/>
    </div>
    )}
      <div className="main-userProfile">
        <div className="userProfile-container">
          {userInfo && userInfo[0].user_role &&(
            userInfo[0].user_role === 'customer' ? 
            <>
              <div className="userProfile-Header">
                <div className="usr-prf">
                  <section>
                    <div className="usrPrfpic">
                      <img src={userInfo[0].user_profile_pic ? `https://2wave.io/skinmiso/customer_profile_picture/${userInfo[0].user_profile_pic}` : defaultProfile} alt="" />
                    </div>
                  </section>
                </div>
                <div className="usr-mn-nme">
                  <section>
                    <span>Username:</span>
                    <h1>{userInfo[0].user_username}</h1>
                    <span>User ID: </span>
                    <h1>{userInfo[0].user_customerID} <IoCopyOutline id='cpyID' onClick={handleCopy}/>{copySuccess === "Copied!" ? <h2>{copySuccess}</h2> : ''}</h1>
                    <span>Email: </span>
                    <h1>{userInfo[0].user_email}</h1>
                    <span>Linked Acc: </span>
                    <h1>
                      {userInfo[0].user_fb_connected === '' && userInfo.user_google_connected === '' ? 
                        <>No linked Account</> :
                        <>
                          {userInfo[0].user_fb_connected !== '' ? <FaFacebook id='scLinkIcon'/> : ''}
                          {userInfo[0].user_google_connected !== '' ? <FaGoogle id='scLinkIcon'/> : ''}
                        </>
                      }
                    </h1>
                  </section>
                  <section>
                    <span>Fullname: </span>
                    <h1>{userInfo[0].user_first_name} {userInfo[0].user_last_name}</h1>
                    <span>Gender: </span>
                    <h1>{userInfo[0].user_gender}</h1>
                    <span>Mobile Phone: </span>
                    <h1>{userInfo[0].user_mobileno}</h1>
                    <span>Birthday: </span>
                    <h1>{userInfo[0].userInfo ? userInfo.user_birthday : 'Birtday not Set'}</h1>
                  </section>
                  <section id='usr-address'>
                    <span>Delivery Address: </span>
                    <p>{userInfo[0].user_address_label} Address:</p>
                    <div className="usrAdd">
                      <h1>{userInfo[0].user_country ? `${userInfo[0].user_houseNo} ${userInfo[0].user_street}, ${userInfo[0].user_district}, ${userInfo[0].user_city}, ${userInfo[0].user_state}, ${userInfo[0].user_country}` : `Address not Set`}</h1>
                      <FiEdit id='editInfo' onClick={openEditLocation}/>
                    </div>
                  </section>
                  <button onClick={openEditData}>Edit Info</button>
                </div>
              </div>
              <div className="userProfile-Contents">
                <div className="usrPrf-hdNav">
                  <ul>
                    <li onClick={switchCart}><FaCartShopping/><span id='usrTooltip'>Cart</span></li>
                    <li onClick={switchHistory}><RiHistoryFill/><span id='usrTooltip'>History</span></li>
                    <li onClick={switchStats}><BsClipboardDataFill/><span id='usrTooltip'>Statisctics</span></li>
                  </ul>
                </div>
                <div className="usrPrf-cntnts">
                  <section className={`usr-cart ${userBarNav}`} id='usrData-cart-cntnts'>
                    <div className="usrCart">
                      <ul>
                        <li>
                          <img src={samplePrd} alt="" />
                          <div className="rmvPrd">
                            <IoIosCloseCircle />
                          </div>
                          <div className="usrPrd-ordr">
                            <h1>Pore Coreset Serum</h1>
                            <hr />
                            <div className='itemDtails'>
                              <p>$213</p>
                              <span className='itemNumber'>
                                <IoIosRemoveCircle id='itmCnt'/>
                                <h2>1</h2>
                                <IoIosAddCircle id='itmCnt'/>
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <img src={samplePrd} alt="" />
                          <div className="rmvPrd">
                            <IoIosCloseCircle />
                          </div>
                          <div className="usrPrd-ordr">
                            <h1>Pore Coreset Serum</h1>
                            <hr />
                            <div className='itemDtails'>
                              <p>$213</p>
                              <span className='itemNumber'>
                                <IoIosRemoveCircle id='itmCnt'/>
                                <h2>1</h2>
                                <IoIosAddCircle id='itmCnt'/>
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <img src={samplePrd} alt="" />
                          <div className="rmvPrd">
                            <IoIosCloseCircle />
                          </div>
                          <div className="usrPrd-ordr">
                            <h1>Pore Coreset Serum</h1>
                            <hr />
                            <div className='itemDtails'>
                              <p>$213</p>
                              <span className='itemNumber'>
                                <IoIosRemoveCircle id='itmCnt'/>
                                <h2>1</h2>
                                <IoIosAddCircle id='itmCnt'/>
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </section>
                  <section className={`usr-history ${userBarNav}`} id='usrData-history-cntnts'>
                    <FunctionUnavailable/>
                  </section>
                  <section className={`usr-stats ${userBarNav}`} id='usrData-stats-cntnts'>
                    <FunctionUnavailable/>
                  </section>
                </div>
              </div>
            </> : 
            <>
              <div className="prtcpnt-container">
                <section className='prtcpnt-info'>
                  <span>
                    <div className="prtcpnt-img">
                      <img src={defaultProfile} alt="" />
                    </div>
                  </span>
                  <h1>Code: GabrielSkinmiso <IoCopyOutline id='cpy-prtcpnt-cd' onClick={handleCopy}/> {copySuccess === "Copied!" ? <h2>{copySuccess}</h2> : ''}</h1>
                </section>
                <section>
                  <div className="prtcpnt-header">
                    <h1>Hi Gabriela</h1>
                    <p>Welcome back!</p>
                    <span>October 2024</span>
                  </div>
                  <div className="prticpnt-ttl-data">
                    <ul>
                      <li>
                        <p>Code Users</p>
                        <h1>146</h1>
                      </li>
                      <li>
                        <p>Total Sales</p>
                        <h1>$2,683.90</h1>
                      </li>
                      <li>
                        <p>Total Earnings</p>
                        <h1>$258.06</h1>
                      </li>
                    </ul>
                  </div>
                  <div className="prtcpnt-contents">
                    <div className="prtcpnt-trnsctn-hstory">
                      <div className="prtcpntTH">
                        <p>Transaction History</p>
                        <div className="trnsctn-dt">
                          <p>Recently</p>
                          <p>Oldest</p>
                          <p>More</p>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <h1>Name</h1>
                          <h1>Product</h1>
                          <h1>Date</h1>
                          <h1>Code</h1>
                        </li>
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                        <hr />
                        <li>
                          <p>Maria Isabela</p>
                          <p>Pore Purifying</p>
                          <p>10/15/2024</p>
                          <p>GabrielSkinmiso</p>
                        </li>
                      </ul>
                    </div>
                    <div className="prtcpnt-data-income">
                      <div className="prtcpnt-ttl-incm">
                        <div className="prtTtlInc-head">
                          <h1>Total Income</h1>
                          <select name="" id="">
                            <option value="">Last 3 Months</option>
                            <option value="">Last 5 Months</option>
                          </select>
                        </div>
                        <div className="prtChrt">
                          <LineChart/>
                        </div>
                      </div>
                      <div className="prtcpnt-goal">
                        <div className="prtcpnt-goal-head">
                          <h1>My Goals</h1>
                          <button>Add Goals</button>
                        </div>
                        <div className="prtcpnt-goal-content">
                          <div className="prtcpt-header">
                            <section>
                              <img src={piggy} alt="" />
                              <span>
                                <h1>Earn</h1>
                                <p>$2,500.05 / $5,000.00</p>
                              </span>
                            </section>
                            <span>
                              <h1>50%</h1>
                            </span>
                          </div>
                          <div className="prtcpnt-prgrss">
                            <progress max="100" value="70">70%</progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Profile