import React, { useState } from 'react';
// css
import '../css/location.css'
// axios
import axios from 'axios';
function AddressAutocomplete({userID,customerAddress,apiBaseURL,setSuccessContent,setCreateSuccess,setEditLocation}) {
  const [houseNumber, setHouseNumber] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [region, setRegion] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [addressLabel,setAddressLabel] = useState('')
  
  const handleStreetChange = async (e) => {
    const input = e.target.value;
    setStreet(input);

    if (input.length > 2) {
      try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
          params: {
            q: input,
            format: 'json',
            addressdetails: 1,
            limit: 5,
          },
        });
      
        const addressSuggestions = response.data.map((location) => ({
          displayName: location.display_name,
          street: location.address.road || '',
          city: location.address.city || location.address.town || location.address.village || '',
          district: location.address.city_district || location.address.neighbourhood || location.address.suburb || '',
          region: location.address.region || location.address.county || '',
          state: location.address.state || '',
          postalCode: location.address.postcode || '',
          country: location.address.country || '',
        }));
        console.log(addressSuggestions);
        
        setSuggestions(addressSuggestions);
      } catch (error) {
        console.error("Error fetching address suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setStreet(suggestion.street);
    setCity(suggestion.city);
    setDistrict(suggestion.district);
    setRegion(suggestion.region)
    setState(suggestion.state);
    setPostalCode(suggestion.postalCode);
    setCountry(suggestion.country);
    setSuggestions([]);
  };

  const updateAddress = () => {
    const addressData = {
      customerID : userID,
      houseNumber : houseNumber,
      street : street,
      city : city,
      district : district,
      region : region,
      state : state,
      postalCode : postalCode,
      country : country,
      addressLabel : addressLabel,
    }
    axios
      .post(`${apiBaseURL}/update-address`, {addressData})
      .then((response) => {
        if (response.data.success.message === 'Successfull updated the address') {
          setSuccessContent("Successfully Edited Address");
          setCreateSuccess(true);
          setEditLocation(false);
        } else {
          setSuccessContent("Successfully Added the Address");
          setCreateSuccess(true);
          setEditLocation(false);
        }
      })
      .catch((error) => {
        console.log(error);
        
      })
  }

  return (
    
    <div className="cstmerLction-container">
      <div className="cstmerLction-header">
        <h1>Set your Address</h1>
      </div>
      <hr />
      <div className='cstmerLction-contents'>
        <span>
          <p>House No./Lot No.:</p>
          <input
            type="number"
            value={houseNumber}
            onChange={(e) => setHouseNumber(e.target.value)}
            placeholder="Enter House or Lot No."
          />
          <p>Street:</p>
          <input
            type="text"
            value={street}
            onChange={handleStreetChange}
            placeholder="Enter street"
          />
          {suggestions.length > 0 && (
            <ul>
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.displayName}
                </li>
              ))}
            </ul>
          )}
          <p>City:</p>
          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="Enter city"
          />

          <p>District:</p>
          <input
            type="text"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
            placeholder="Enter district"
          />
        </span>
        <span>
          
          <p>Region:</p>
          <input
            type="text"
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            placeholder="Enter Region"
          />
          <p>State/Province:</p>
          <input
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            placeholder="Enter state or province"
          />
          <p>Postal Code:</p>
          <input
            type="text"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            placeholder="Enter postal code"
          />

          <p>Country:</p>
          <input
            type="text"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder="Enter country"
          />
        </span>
      </div>
      <div className="lction-label">
        <p>Address Label as:</p>
        <input 
          type="text" 
          placeholder='Work / House' 
          value={addressLabel}
          onChange={(e) => setAddressLabel(e.target.value)}
        />
      </div>
      <div className="save-location">
        <button onClick={updateAddress}>Save Address</button>
      </div>
    </div>
  );
}

export default AddressAutocomplete;
